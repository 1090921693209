import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';

type PaymentsData = {
  allPaymensYaml: {
    edges: {
      node: {
        title: string;
        content: string;
      };
    }[];
  };
};

const query = graphql`
  query {
    allPaymensYaml {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`;

const Payments: React.FC = () => {
  const data = useStaticQuery<PaymentsData>(query);
  const paymentsData = data.allPaymensYaml.edges.map(({node}) => node);

  return (
    <Layout>
      <Container className="general">
        <PageTitle title="Способи оплати" />

        {paymentsData.map(section => (
          <ExpandableSection summary={section.title} innerHTML={section.content} />
        ))}
      </Container>
    </Layout>
  );
};

export default Payments;
